/* eslint-disable no-alert, complexity */
import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { format } from 'date-fns'
import { Link, navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import qs from 'qs'
import { get, some } from 'lodash'

import useVerifyRole from '../../hooks/verifyRole'
import Hero from '../../components/Hero'
import Section from '../../components/Section'
import SEO from '../../components/SEO'
import { Input, Loader } from '../../_shared/components'
import Button from '../../components/Button'

const voucherStatusMap = {
  used: 'brukt',
  unused: 'ubrukt',
  revoked: 'trukket tilbake',
}

export default function Page() {
  const { loading, hasRole } = useVerifyRole(['admin'])
  const [settingPassord, setSettingPassword] = useState(false)
  const [newPassword, setNewPassword] = useState('')

  // pull the code out of the url
  const { search } = useLocation()
  const { id = null, query = '' } = qs.parse(search, {
    ignoreQueryPrefix: true,
  })

  const isIdSearch = query && !Number.isNaN(Number(query))

  const [
    performQuery,
    { loading: searchLoading, data: { userSearch } = {} },
  ] = useLazyQuery(
    gql`
      query UserSearch($query: String!) {
        userSearch(query: $query) {
          edges {
            node {
              id
              email
              plans {
                product {
                  title
                }
              }
            }
          }
        }
      }
    `,
    { fetchPolicy: 'network-only' },
  )

  const [
    getUser,
    { loading: userLoading, data: { user: { node: user = null } = {} } = {} },
  ] = useLazyQuery(
    gql`
      query User($id: ID!) {
        user(id: $id) {
          node {
            id
            email
            fbUserId
            appleUserId
            hasPassword
            firstName
            lastName
            roles
            charges {
              id
              due
              amount
              refunded
              vippsChargeId
              plan {
                id
                paymentType
                product {
                  id
                  title
                }
                variant {
                  id
                  months
                  price
                }
              }
              status
            }
            plans {
              id
              months
              renewal
              product {
                id
                title
                description
                type
              }
              variant {
                id
                months
                price
              }
              end
              amount
              paymentType
              paymentTypeStore
              paymentAgreementStatus
            }
          }
        }
      }
    `,
    { fetchPolicy: 'network-only' },
  )

  const [setPassword] = useMutation(gql`
    mutation SetPassword($userId: ID!, $password: String!) {
      setPassword(userId: $userId, password: $password) {
        success
      }
    }
  `)

  useEffect(() => {
    if (!query || !query?.length) {
      return
    }
    performQuery({ variables: { query } })
  }, [performQuery, query])

  useEffect(() => {
    if (!id) {
      return
    }

    resetSetPasswordState()
    getUser({ variables: { id } })
  }, [getUser, id])

  function resetSetPasswordState() {
    setSettingPassword(false)
    setNewPassword('')
  }

  function handleQueryChange(query) {
    // update url with the query typed in by the user
    navigate(`/admin/brukere?${qs.stringify({ query })}`, { replace: true })
  }

  async function handleSetNewPassword() {
    if (
      window.confirm(
        `Er du sikker på at du vil sette nytt passord for ${user?.email}?`,
      )
    ) {
      await setPassword({
        variables: { userId: user.id, password: newPassword },
      })
      resetSetPasswordState()
    }
  }

  if (loading) {
    return <Loader />
  }

  if (!hasRole) {
    return null
  }

  const vippsExtendedByVoucher =
    some(get(user, 'plans', []), { paymentType: 'voucher' }) &&
    some(get(user, 'plans', []), { paymentType: 'vipps' })

  return (
    <>
      <SEO title="Brukere" />
      <Hero
        title={
          <>
            <Link to="/admin">Admin</Link> &gt; Brukere
          </>
        }
        type={['center', 'small']}
      />
      <Section size="small">
        <div style={{ minHeight: 'calc(100vh - 48.3rem)' }}>
          <p>
            Her kan du søke opp en bruker på id, eller med en del av
            e-postadressen.
          </p>
          <br />
          <Input
            type="text"
            label="F.eks. brukerid eller epost"
            value={query}
            onChange={handleQueryChange}
          />

          {!id && query && !searchLoading && userSearch?.edges?.length === 0 ? (
            <>
              {isIdSearch ? `Fant ikke noen bruker med id ${query}` : null}
              {!isIdSearch &&
                (query.length < 3
                  ? 'Du må skrive inn minst tre tegn'
                  : `Fant ingen brukere med '${query}' i e-postadressen`)}
            </>
          ) : null}

          {userLoading || searchLoading ? <Loader delay={0} /> : null}

          {userSearch?.edges?.length ? (
            <ul
              style={{
                borderTop: '2px solid var(--color-highlighted)',
                marginTop: '1rem',
              }}
            >
              <li
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottom: '2px solid var(--color-highlighted)',
                  padding: '10px 0',
                }}
              >
                <span style={{ fontWeight: 'bold' }}>Bruker</span>
                <span style={{ fontWeight: 'bold' }}>Aktivt abonnement</span>
              </li>
              {userSearch?.edges.map(({ node: user }) => (
                <li
                  key={user.id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom: '2px solid var(--color-highlighted)',
                    padding: '10px 0',
                  }}
                >
                  <span>
                    <Link
                      to={`/admin/brukere?${qs.stringify({
                        id: user.id,
                        query,
                      })}`}
                    >
                      {user.email}
                    </Link>
                  </span>
                  <span>{user?.plans?.[0]?.product?.title}</span>
                </li>
              ))}
            </ul>
          ) : null}

          {id && user ? (
            <div style={{ marginTop: '3rem' }}>
              <Section
                type="center"
                title={`${user.email} (${user.id}) `}
                style={{ textAlign: 'center' }}
              >
                {user.plans.length > 0 && (
                  <>
                    {vippsExtendedByVoucher && (
                      <p>
                        Brukeren har et Vipps-abonnement og har løst inn et
                        gavekort. Vipps-abonnementet fortsetter når
                        gavekort-perioden er over.
                      </p>
                    )}

                    {user.plans.map((plan, index) => (
                      <div key={plan.id}>
                        {index > 0 ? (
                          <React.Fragment>
                            <br />
                            <br />
                          </React.Fragment>
                        ) : null}
                        <p>
                          {plan.paymentType === 'voucher' ? (
                            <React.Fragment>
                              {plan.product.title} til den{' '}
                              {format(new Date(plan.end), 'dd.MM.yyyy')}
                              <br />
                              Betalt med gavekort
                            </React.Fragment>
                          ) : null}

                          {plan.paymentType === 'vipps' ? (
                            <React.Fragment>
                              {plan.product.title}
                              <br />
                              {plan.amount / 100},- i måneden.
                              <br />
                              {plan.renewal ? 'Fornyes' : 'Løper ut'} den{' '}
                              {format(new Date(plan.end), 'dd.MM.yyyy')}
                              <br />
                              Betaling med Vipps
                            </React.Fragment>
                          ) : null}

                          {plan.paymentType === 'inApp' ? (
                            <React.Fragment>
                              {plan.product.title} til den{' '}
                              {format(new Date(plan.end), 'dd.MM.yyyy')}
                              <br />
                              Betaling gjennom{' '}
                              {plan.paymentTypeStore.toLocaleUpperCase() ===
                              'PLAY_STORE'
                                ? 'Google Play'
                                : 'App Store'}
                            </React.Fragment>
                          ) : null}
                        </p>
                      </div>
                    ))}
                  </>
                )}

                <br />
                <br />
                {!settingPassord ? (
                  <Button
                    small
                    primary
                    onClick={() => setSettingPassword(true)}
                  >
                    Sett nytt passord
                  </Button>
                ) : (
                  <div>
                    <Input
                      type="text"
                      label="Det nye passordet"
                      value={newPassword}
                      onChange={setNewPassword}
                    />
                    {newPassword && newPassword.length < 6 ? (
                      <>
                        Passordet må være minst seks tegn
                        <br />
                        <br />
                      </>
                    ) : null}
                    <Button
                      small
                      primary
                      disabled={newPassword?.length < 6}
                      onClick={handleSetNewPassword}
                    >
                      Lagre
                    </Button>{' '}
                    <Button small primary onClick={resetSetPasswordState}>
                      Avbryt
                    </Button>
                  </div>
                )}
              </Section>
            </div>
          ) : null}
        </div>
      </Section>
    </>
  )
}
